<template>
    <div class="home">
    <Header/>
    <Nav/>
  </div>
  <div class="home">
    
  </div>
</template>

<script>
import Header from '@/components/LayoutPage/Header.vue'
import Nav from '@/components/LayoutPage/Nav.vue'
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    Header, Nav
  }
}
</script>
