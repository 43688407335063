<template>
<div class="topnav">
  <router-link to="/">Home</router-link>
  <router-link to="/contact1">Trasy</router-link>
  <router-link to="/przystanki">Przystanki</router-link>
<!--
  <router-link to="/about">About</router-link>
  <router-link to="/contact">Contact</router-link>
  <router-link to="/linia3">Linia3</router-link>
  <router-link to="/busstop">Bus Stop</router-link>
--> 
</div>  
</template>

<script>
export default {
name: 'Nav'
}
</script>

<style>
 /* The navbar container */
.topnav {
  overflow: hidden;
  /* background-color: #483D8B;  #191970 */
  background: linear-gradient(to left, #483D8B, #191970);
}

/* Navbar links */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Links - change color on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
} 
</style>