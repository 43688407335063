<template>
  <div class="header">
    <h4>Header</h4>
  </div> 
</template>

<script>
export default {
name: 'Header'
}
</script>

<style>
.header {
  background-color: #008B8B;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 12px 12px 0 0;
}
</style>